import { Link, PageProps } from 'gatsby';
import React, { useEffect } from 'react';
import CommonBanner from '../components/banner/CommonBanner';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Company = ({ location }: PageProps) => {
  useEffect(() => {
    console.log(location.hash);
    setTimeout(() => {
      window.location.hash = '';
      window.location.hash = location.hash;
    });
  }, [location.hash]);
  return (
    <Layout>
      <Seo
        title="About banco| Financing platform for growing businesses in Asia"
        description="banco is a financing platform for growing businesses in Asia. Unlock opportunities in supply chain to empower companies control cash flow and scale business"
      />
      <main className="main-wrapper">
        {/* Company */}
        <CommonBanner>
          <HeaderCommon />

          <div className="company-wrap">
            <div className="content-1 row">
              <div className="-left col">
                <img className="light-mode" src="/images/logo-sticky.png" style={{ width: 245 }} />
                <p className="-title">We’re building better finance together</p>
                <p className="-description">
                  by making it simple, transparent and accessible for you
                </p>
              </div>
              <div className="col">
                <img className="light-mode" src="/images/company-main.png" style={{ width: 541 }} />
              </div>
            </div>
            <p className="content-description">
              <span>banco</span> is a financing platform for growing businesses in Asia, designed by
              entrepreneurs and professionals in finance and technology. <br />
              <br />
              We solve industry challenges by constantly reinventing products which actually works
              for businesses.
              <br />
              Unlocking previously inaccessible opportunities in the supply chain. <br />
              We empower all companies to take control of their cashflow and build great businesses.
            </p>
            <div className="row row-8">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Link className="banco-btn btn-fill-primary btn-fluid" to="/contact">
                  Speak with our team
                </Link>
              </div>
            </div>
            <div className="row content-2">
              <div className="card col-4">
                <div>
                  <img
                    className="light-mode"
                    src="/images/finance-svg.png"
                    style={{ width: 130 }}
                  />
                </div>
                <div>
                  <p className="-title">Finance</p>
                  <p className="-description">
                    Receivables Finance, Supply Chain Finance, Deep-tier Supply Chain Finance, SME
                    Finance, Green Finance.
                  </p>
                </div>
              </div>
              <div className="card col-4">
                <div>
                  <img className="light-mode" src="/images/manage-svg.png" style={{ width: 125 }} />
                </div>
                <div>
                  <p className="-title">Manage</p>
                  <p className="-description">
                    Vendors & Order, Account Payables/Account Receivables, Trade and Logistics Data,
                    Integration with enterprise systems.
                  </p>
                </div>
              </div>
              <div className="card col-4">
                <div>
                  <img className="light-mode" src="/images/green-svg.png" style={{ width: 134 }} />
                </div>
                <div>
                  <p className="-title">Green</p>
                  <p className="-description">
                    Sustainability assessment, Analysis & benchmarking dashboard, sustainability
                    report.
                  </p>
                </div>
              </div>
            </div>
            <p className="-breadcrumb" id="partners">
              Our Partners
            </p>
            <div className="content-3">
              <div className="row item-row">
                {/* <div className="col">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/logo-sbi-group-img.png"
                      style={{ width: 197, margin: '-16px 0 0 -16px' }}
                    />
                  </div>
                  <div>
                    <p>
                      SBI Group is a corporate entity that provides a variety of one-stop financial
                      services over the Internet now that two major events, the Financial Big Bang
                      resulting from deregulation, and the Internet Revolution resulting from IT
                      innovation, have radically transformed the financial industry.
                    </p>
                  </div>
                </div> */}
                <div className="col">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/logo-sap-img.png"
                      style={{ width: 108 }}
                    />
                  </div>
                  <div>
                    <p>
                      SAP innovations help 440,000 customers worldwide work together more
                      efficiently and use business insight more effectively. Explore our leadership,
                      history, sustainability, diversity, FAQs, and more.
                    </p>
                  </div>
                </div>
                {/*</div>
               <div className="row item-row">
                <div className="col">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/logo-salvus-img.png"
                      style={{ width: 138, margin: '0 0 0 -5px' }}
                    />
                  </div>
                  <div>
                    <p>
                      Granted the license to operate by the Ministry of Finance in 2005
                      [KEP-427/KM.5/2005]. Started to operate under the name Salvus in 2014. PT.
                      Salvus Inti is registered in and overseen by OJK.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/logo-mambu-img.png"
                      style={{ width: 267, margin: '-36px 0px -42px -50px' }}
                    />
                  </div>
                  <div>
                    <p>
                      Mambu gives financial institutions a powerful competitive edge by allowing
                      them to rapidly create, launch and service any lending or deposit portfolio
                      through a modern SaaS banking platform.
                    </p>
                    <p>
                      Built from the cloud up, Mambu is a powerful alternative to the costs and
                      complexity of traditional core banking systems or custom in-house solutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row item-row">
                <div className="col">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/logo-tencent-img.png"
                      style={{ width: 152 }}
                    />
                  </div>
                  <div>
                    <p>
                      Tencent Holdings Ltd, also known as Tencent, is a Chinese multinational
                      technology conglomerate holding company. Founded in 1998, its subsidiaries
                      globally market various Internet-related services and products, including in
                      entertainment, artificial intelligence, and other technology.
                    </p>
                    <p>
                      The company surpassed a market value of US$500 billion in 2018, becoming the
                      first Asian technology company to cross this valuation mark.It has since then
                      emerged as one of Asia’s most valuable companies, and among the world’s top
                      technology companies by market value.Tencent has been credited as one of the
                      world’s most innovative companies by the Boston Consulting Group and Fast
                      Company respectively.As of 2018, Tencent has the 5th highest global brand
                      value.It has stakes in over 600 companies, recently focusing on tech start-ups
                      in Asia.
                    </p>
                  </div>
                </div> */}
                <div className="col">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/logo-r3-img.png"
                      style={{ width: 93, margin: '0 0 0 -15px' }}
                    />
                  </div>
                  <div>
                    <p>
                      R3 is an enterprise software firm that is pioneering digital industry
                      transformation. They deliver purpose-built distributed ledger technology for
                      all types of businesses in all industries.
                    </p>
                    <p>
                      R3’s global team of over 300 professionals in 14 countries is supported by
                      over 2,000 technology, financial, and legal experts drawn from its global
                      member base.
                    </p>
                    <p>
                      The Corda platform is already being used in industries from financial services
                      to healthcare, shipping, insurance, and more. It records, manages and executes
                      institutions’ financial agreements in perfect synchrony with their peers,
                      creating a world of frictionless commerce.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="row item-row pc-last-row">
                <div className="col">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/logo-delt-img.png"
                      style={{ width: 194, margin: '0px 0px -20px -15px' }}
                    />
                  </div>
                  <div>
                    <p>
                      Delta Insurance Group is a group of locally owned and operated specialist
                      underwriting agencies in New Zealand and Singapore. As Coverholders of Lloyd’s
                      of London, we provide comprehensive insurance coverage solutions across a
                      range of products in the property, financial lines, casualty and aviation
                      insurance sectors.
                    </p>
                    <p>
                      Delta Insurance Group was founded in 2014 in New Zealand and expanded into
                      Asia in 2017 launching as the first Lloyd’s of London Coverholder in
                      Singapore. In January 2018 Delta Property Insurance was added to the group
                      providing specialist Commercial Property insurance in New Zealand.
                    </p>
                    <p>
                      In 2017 Delta Insurance New Zealand made the Deloitte Fast 50, representing
                      the fastest growing companies in New Zealand, coming in at number 34. In the
                      same year the company was honoured to receive the Underwriting Agency of the
                      Year award by ANZIIF and Insurer of the Year award by the Insurance
                      Advisernet.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/logo-htc-img.png"
                      style={{ width: 276, margin: '24px 0 0 0' }}
                    />
                  </div>
                  <div>
                    <p>
                      Hunter Technology Corp. develops interactive software platforms powered by
                      blockchain technology that digitalize and streamline physical oil trading
                      throughout the transaction lifecycle. With its solutions, Hunter delivers more
                      favorable economics and fair market access for all and promotes the transition
                      towards a more environmentally and ethically responsible ecosystem. Its
                      flagship product OilEx will connect independent oil producers, buyers, and
                      traders in a trusted digital marketplace to optimize prices, simplify
                      processes, improve transparency, and support a reduced carbon footprint.
                      Through its data analytics capabilities, Hunter will offer real time supply
                      chain management tools for tracking the origin, transhipment, and processing
                      of hydrocarbons and the environmental, social and governance (ESG) compliance
                      during their life cycle.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </CommonBanner>

        <Footer />
      </main>
    </Layout>
  );
};

export default Company;
